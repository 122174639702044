const {useEffect, useRef} = require('react')

export const TvGlitch = ({children, opacity}) => {
  const filterRef = useRef()

  useEffect(() => {
    function loop() {
      const turbVal = {val: Math.random() * 0.1}
      const turb = filterRef.current.querySelector('feTurbulence')
      turb.setAttribute('baseFrequency', '0 ' + turbVal.val)
    }
    const interval = setInterval(loop, 150)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <div className="tv-glitch" style={{'--opacity': opacity}}>
        <svg>
          <defs>
            <filter
              id="noise"
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              ref={filterRef}
            >
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0 0.000001"
                result="NOISE"
                numOctaves="2"
              />
              <feDisplacementMap
                in="SourceGraphic"
                in2="NOISE"
                scale="100"
                xChannelSelector="R"
                yChannelSelector="R"
              ></feDisplacementMap>
            </filter>
          </defs>
        </svg>
        <img className="tv-glitch__image" src="/images/tv-glitch.png" />
        {children}
      </div>
    </>
  )
}
