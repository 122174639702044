import Link from 'next/link'
import React, {useState, useEffect, useContext} from 'react'
import {getLevelStats} from 'src/util/db'
import {UserContext} from 'src/contexts'
import {Avatar, PlayerName} from 'src/Common'
import {CountDown} from './CountDown'
import {normalizeDate} from '@cssbattle/shared/utils'
import {BattleGameModes} from '@cssbattle/shared/battles'
import {VStack} from '@components/Stack'
import {Text} from '@components/Text'
import {Icon} from './Icon'
import {TvGlitch} from '@components/TvGlitch'
import {cdnifyStorageUrl, getHumanReadableDate} from './utils'

function LevelTileUi({data, playerData = {}, stats, unlockDate, gameMode}) {
  const user = useContext(UserContext)

  const [isLocked, setIsLocked] = useState(!data.id)
  useEffect(() => {
    setIsLocked(!data.id)
  }, [data.id])

  data.image = isLocked
    ? data.isOpen
      ? '/targets/0-open.png'
      : '/targets/0.png?v=2'
    : data.image
  const image2xSrc = data.image?.replace(/\.png/, '@2x.png')

  return (
    <div
      className={`target-tile ${data.id ? '' : 'target-tile--dummy'} ${
        data.isOpen ? 'target-tile--public' : ''
      } ${
        stats &&
        stats.highscore.playerProfile.userId === user?.uid &&
        'target-tile--glowing'
      }`}
    >
      <div className="target-top-info">
        {data.id ? (
          <p className="target-top-info__pill">
            {data.id.length > 5 ? data.name || `#${data.id}` : `#${data.id}`}
          </p>
        ) : null}
        {data.isOpen ? (
          <p className="target-top-info__pill">
            <img alt="" src="/images/open-book.svg" />
          </p>
        ) : null}
      </div>
      <VStack gap="1rem" align="stretch" fullHeight>
        {data.id && (
          <Link href={`/play/${data.id}`}>
            <a
              className="shadow-link"
              title={`Play target number ${data.id}`}
            ></a>
          </Link>
        )}

        {data.id ? (
          <img
            src={data.image}
            srcSet={`${image2xSrc} 2x`}
            alt=""
            loading="lazy"
            className="target-tile__image"
          />
        ) : (
          <TvGlitch opacity="0.01">
            <img
              src="/images/lock.svg"
              width="33"
              height="43"
              style={{opacity: 0.75}}
            />
          </TvGlitch>
        )}
        <div style={{flex: 1}}>
          <VStack gap="0" align="stretch" justify="space-around" fullHeight>
            {stats && gameMode !== 'firstToMatch' ? (
              <div>
                <VStack gap="1rem">
                  <Avatar
                    small
                    size="40"
                    url={stats.highscore.playerProfile.avatar}
                    userId={stats.highscore.playerProfile.userId}
                    isPro={stats.highscore.playerProfile.isPro}
                    username={stats.highscore.playerProfile.username}
                    className="target-highscore__avatar"
                  />
                  <VStack gap="0.4rem">
                    <PlayerName
                      name={stats.highscore.playerProfile.displayName}
                      username={stats.highscore.playerProfile.username}
                      userId={stats.highscore.playerProfile.userId}
                      ellipsis
                      rank={1}
                    />
                    <p className="target-highscore__score">
                      {stats.highscore.score}{' '}
                      {gameMode !== 'firstToMatch' ? (
                        <span>
                          {'{'}
                          {stats.highscore.codeSize}
                          {'}'}
                        </span>
                      ) : null}
                    </p>
                  </VStack>
                </VStack>
              </div>
            ) : null}

            {!stats && !isLocked && (
              <div>
                <VStack gap="0.25rem">
                  <Text
                    mono={false}
                    size={4}
                    weight={500}
                    appearance="tertiary"
                  >
                    😭
                  </Text>
                  <Text
                    mono={false}
                    size={1}
                    weight={500}
                    appearance="secondary"
                  >
                    No highscore yet
                  </Text>
                  <Text
                    mono={false}
                    size={0}
                    weight={500}
                    appearance="tertiary"
                  >
                    Be the first?
                  </Text>
                </VStack>
              </div>
            )}

            {isLocked && unlockDate ? (
              <>
                <div
                  className="daily-target-text"
                  style={{padding: '1rem 1rem 0.5rem'}}
                >
                  <p
                    className="daily-target-text__title"
                    style={{textAlign: 'center'}}
                  >
                    Unlocks in
                  </p>
                  <div style={{marginTop: '1rem'}}>
                    <CountDown
                      showEmoji={false}
                      suffix=""
                      date={unlockDate}
                      size="0.1"
                      showUnits={true}
                      maxLength={2}
                      justify="center"
                    />
                  </div>
                </div>
              </>
            ) : null}

            {user && !isLocked && (
              <div
                style={{
                  marginTop: '1rem',
                }}
              >
                <>
                  <div className="target-attempts">
                    <p
                      className={`target-attempts__item  target-attempts__item--score ${
                        !playerData.score && 'target-attempts__item--not-played'
                      }`}
                    >
                      {(() => {
                        if (
                          stats &&
                          stats.highscore.playerProfile.userId === user?.uid
                        ) {
                          return <span>That's you!</span>
                        } else if (playerData.score) {
                          return (
                            <span>
                              You - {playerData.score}{' '}
                              {gameMode !== 'firstToMatch' ? (
                                <span>
                                  {'{'}
                                  {playerData.codeSize || '-'}
                                  {'}'}
                                </span>
                              ) : null}
                            </span>
                          )
                        } else {
                          return <span>You - Not played</span>
                        }
                      })()}
                    </p>
                  </div>
                </>
              </div>
            )}
          </VStack>
        </div>
        {data.isOpen ? (
          <div className="public-target-message">
            <p>
              This is an "Open" Target. Open Targets have all the top solutions
              public.{' '}
              <a className="link" href="/blog/introducing-open-targets/">
                Learn More
              </a>
            </p>
          </div>
        ) : null}
      </VStack>
    </div>
  )
}
export function LevelTileBase({
  target,
  showTags,
  data,
  lazy = true,
  useCdnUrl,
  children,
}) {
  target.image = isLocked
    ? target.isOpen
      ? '/targets/0-open.png'
      : '/targets/0.png?v=2'
    : target.image

  const imageSrc = useCdnUrl ? cdnifyStorageUrl(target.image) : target.image
  let image2xSrc = target.image?.replace(/\.png/, '@2x.png')
  image2xSrc = useCdnUrl ? cdnifyStorageUrl(image2xSrc) : image2xSrc

  const [isLocked, setIsLocked] = useState(!target.id)
  useEffect(() => {
    setIsLocked(!target.id)
  }, [target.id])

  return (
    <div
      className={`target-tile--daily  target-tile ${
        target.id ? '' : 'target-tile--dummy'
      } ${target.isOpen ? 'target-tile--public' : ''}`}
    >
      {target.id && target.id !== 'dummy' && (
        <Link href={`/play/${target.id}`}>
          <a
            className="shadow-link"
            title={`Play target for ${getHumanReadableDate(target.startDate, {
              showTime: false,
              utc: true,
            })}`}
          ></a>
        </Link>
      )}
      {target.id === 'dummy' ? (
        <TvGlitch>
          <img
            src="/images/lock.svg"
            width="33"
            height="43"
            style={{opacity: 0.75}}
          />
        </TvGlitch>
      ) : (
        <img
          src={imageSrc}
          srcSet={`${image2xSrc} 2x`}
          alt=""
          loading={lazy ? 'lazy' : 'eager'}
          className="target-tile__image"
        />
      )}

      {showTags && (
        <div className="target-tile__info">
          <div className="target-top-info">
            {target ? (
              <p className="target-top-info__pill">
                {data ? (
                  <Icon name="tick-round" size={'1em'} color="white" />
                ) : null}{' '}
                {target.formattedDate}
              </p>
            ) : null}
          </div>
        </div>
      )}

      {children}

      {/* <div
        style={{
          backgroundColor: '#111318',
          borderRadius: '0.5rem',
          marginTop: '1rem',
        }}
      >
        {user && !isLocked && (
          <>
            <div className="target-attempts">
              <p className="target-attempts__item  target-attempts__item--score">
                Score:
                <span>
                  {playerData.score || 'N/A'}{' '}
                  {gameMode !== 'firstToMatch' ? (
                    <span>
                      {'{'}
                      {playerData.codeSize || '-'}
                      {'}'}
                    </span>
                  ) : null}
                </span>
              </p>
            </div>
          </>
        )}
      </div> */}
    </div>
  )
}

/**
 *
 * @param {number} index Index of target
 * @param {Array} batchSizes list of sizes of each batch
 * @returns eg. 3,[1,2,3] means each batch is 1,2,3 targets long respectively and we
 * to find the batch number of the 2nd target
 */
function getBatchFromIndex(index, batchSizes) {
  // Check if batch sizes are valid
  if (!Array.isArray(batchSizes) || batchSizes.length === 0) {
    throw new Error('Invalid batch sizes')
  }

  // Calculate the cumulative sum of batch sizes
  const cumulativeSum = batchSizes.reduce(
    (acc, size) => [...acc, acc[acc.length - 1] + size],
    [0]
  )

  // Find the batch the index belongs to
  let batchNum = 0
  for (let i = 0; i < cumulativeSum.length; i++) {
    if (index < cumulativeSum[i]) {
      batchNum = i
      break
    }
  }

  return batchNum - 1
}

export function LevelTile(props) {
  const [levelStats, setLevelStats] = useState()
  const [unlockDate, setUnlockDate] = useState()
  const gameMode = props.gameMode

  useEffect(() => {
    // Reset to initial state
    setLevelStats()
    setUnlockDate()

    // If this is a dummy tile, noop
    if (!props.data.id) return undefined

    let levelId = props.data.id
    getLevelStats(props.data.id).then((stats) => {
      if (levelId === props.data.id) setLevelStats(stats)
    })

    return () => {
      levelId = null
    }
  }, [props.data.id])

  useEffect(() => {
    // get batch number based on data.props.index where 2 index are in the same batch
    const batchNo =
      gameMode === BattleGameModes.FIRST_TO_MATCH
        ? 0
        : props.targetBatches
        ? getBatchFromIndex(props.index, props.targetBatches)
        : Math.floor(props.index / 2)

    // get one week added to props.data.battleStartDate to get the unlock date
    if (props.battleStartDate) {
      const _unlockDate = normalizeDate(props.battleStartDate)
      _unlockDate.setDate(_unlockDate.getDate() + 7 * batchNo)
      setUnlockDate(_unlockDate)
    }
  }, [props.data.id])

  return (
    <LevelTileUi
      {...props}
      stats={levelStats}
      unlockDate={unlockDate}
      gameMode={gameMode}
    />
  )
}
