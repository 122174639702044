const NON_CSS_START_LEVEL_ID = 141

function isNonCssAvailable({levelId, battleId}) {
  if (levelId) {
    return levelId >= NON_CSS_START_LEVEL_ID
  }
  if (battleId) {
    return battleId > 22
  }
}

/**
 * Date can be either a datetime string or a firebase timestamp object. This function
 * normalizes to date object
 * @param {string|object} date Date string or object
 */
function normalizeDate(date) {
  if (typeof date === 'string') {
    return new Date(date)
  }

  return date.toDate
    ? date.toDate()
    : new Date((date.seconds || date._seconds) * 1000)
}

function getLocaleDate(date, timeZone) {
  try {
    return date.toLocaleString('en-US', {timeZone})
  } catch (e) {
    return date.toLocaleString('en-US', {timeZone: 'America/New_York'})
  }
}

function isSameDate(date1, date2, timeZone) {
  if (!date1 || !date2) return false
  const date1InTimeZone = new Date(
    getLocaleDate(date1.toDate ? date1.toDate() : date1, timeZone)
  )
  const date2InTimeZone = new Date(
    getLocaleDate(date2.toDate ? date2.toDate() : date2, timeZone)
  )
  return (
    date1InTimeZone.getDate() === date2InTimeZone.getDate() &&
    date1InTimeZone.getMonth() === date2InTimeZone.getMonth() &&
    date1InTimeZone.getFullYear() === date2InTimeZone.getFullYear()
  )
}

function isTodaysDailyTarget(level) {
  if (!level.startDate) {
    return false
  }

  const startDate = level.startDate.toDate()
  const today = new Date()
  return (
    startDate.getUTCDate() === today.getUTCDate() &&
    startDate.getUTCMonth() === today.getUTCMonth() &&
    startDate.getUTCFullYear() === today.getUTCFullYear()
  )
}

function isYesterdaysDailyTarget(level) {
  if (!level.startDate) {
    return false
  }

  const startDate = level.startDate.toDate()
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return (
    startDate.getUTCDate() === yesterday.getUTCDate() &&
    startDate.getUTCMonth() === yesterday.getUTCMonth() &&
    startDate.getUTCFullYear() === yesterday.getUTCFullYear()
  )
}

function getTomorrowDate() {
  var d = new Date()
  d.setUTCHours(24, 0, 0, 0)

  return normalizeDate(d)
}

module.exports = {
  isNonCssAvailable,
  NON_CSS_START_LEVEL_ID,
  normalizeDate,
  isSameDate,
  isTodaysDailyTarget,
  isYesterdaysDailyTarget,
  getTomorrowDate,
  getLocaleDate,
}
